
  import Vue from 'vue'
  import SpaceStatistic from '@/components/SpaceStatistic.vue'

  export default Vue.extend({
    name: 'Statistic',
    components: {
      SpaceStatistic
    }
  })
